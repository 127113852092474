import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { InMemoryCache } from '@apollo/client/core';
import { BASE_PATH as BPM_BASE_PATH } from '@data-access/bpm-generated';
import {
  AppWrapperModule,
  DS_APP_WRAPPER_CONFIG,
  DsAppWrapperConfig,
} from '@design-system/feature/app-wrapper-v2';
import { DsPageModule } from '@design-system/feature/page';
import { AuthTokens, SharedFeatAuthModule } from '@features/auth';
import { SharedLibFeatTranslationModule } from '@features/translation';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BASE_PATH as USER_PROFILE_BASE_PATH } from '@paldesk/shared-lib/data-access/identity-service-generated';
import { BASE_PATH as NOTIFICATIONS_BASE_PATH } from '@paldesk/shared-lib/data-access/notifications-generated';
import {
  GoogleAnalytics4MeasurementId,
  GoogleAnalytics4Module,
} from '@shared-lib/google-analytics';
import {
  API_MANAGEMENT_CONFIG_TOKEN,
  HttpErrorInterceptor,
  HttpInterceptorModule,
  HttpInterceptorTokens,
} from '@utils/interceptors';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { NotificationsStore } from './notifications-store/notifications.store';
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    AppWrapperModule.forRoot([
      {
        provide: DS_APP_WRAPPER_CONFIG,
        useFactory: (): DsAppWrapperConfig => ({
          environment: environment.environment,
          apiBasePath: environment.palfinger_api_management,
          paldeskBasePath: environment.LINKS.PALDESK_BASEPATH,
          paldeskSupportPath: environment.LINKS.STATUS_PALDESK_PAGE,
          appInsightsRole: 'FE: Status Paldesk',
          appName: 'Status Paldesk',
          appGatewayBasePath: environment.palfinger_app_gateway,

          statusBannerProductsToCheck: [],
        }),
      },
    ]),
    EffectsModule.forRoot(),
    StoreModule.forRoot({}),
    ApolloModule,
    DsPageModule,
    MatIconModule,
    SharedLibFeatTranslationModule,
    HttpInterceptorModule.forRoot({
      provide: API_MANAGEMENT_CONFIG_TOKEN,
      useFactory: () => ({
        subscriptionKey: environment.palfinger_api_management_subs_key,
        whitelistedDomains: environment.domainsForApiManagementSubsKey,
      }),
    }),
    // Google Analytics
    GoogleAnalytics4Module.forRoot({
      provide: GoogleAnalytics4MeasurementId,
      useFactory: () => environment.google_analytics_id,
    }),
    SharedFeatAuthModule.forRoot(),
    FlexModule,
  ],
  providers: [
    DatePipe,
    // HTTP INTERCEPTORS
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HttpInterceptorTokens.identityServerAuthConf,
      useFactory: () => environment.identityServerAuthConf,
    },
    // Apollo GraphQL
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => ({
        cache: new InMemoryCache(),
        link: httpLink.create({
          uri: environment.palfinger_app_gateway + '/status/graphql',
        }),
      }),
      deps: [HttpLink],
    },
    {
      provide: NOTIFICATIONS_BASE_PATH,
      useFactory: () => environment.palfinger_app_gateway + '/notification',
    },
    {
      provide: BPM_BASE_PATH,
      useFactory: () => environment.palfinger_app_gateway + '/bpm',
    },
    // User Profile
    {
      provide: USER_PROFILE_BASE_PATH,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    // AUTH
    {
      provide: AuthTokens.oidcScope,
      useFactory: () => environment.oidc_scope,
    },
    {
      provide: AuthTokens.securityTokenService,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    {
      provide: AuthTokens.postLogoutRedirectUri,
      useFactory: () => environment.LINKS.POST_LOGOUT_REDIRECT_URI,
    },
    // AUTH
    {
      provide: AuthTokens.oidcClientId,
      useFactory: () => environment.oidc_clientid,
    },
    NotificationsStore,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
