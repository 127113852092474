import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Menu } from '@design-system/feature/app-wrapper-v2';
import { environment } from '../environments/environment';
@Component({
  selector: 'status-paldesk-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  menu: Menu = {
    title: 'PALDESK Status',
    titleIcon: 'traffic',
    navigation: [
      {
        title: 'status_paldesk.current_status',
        routerLink: '/current',
        icon: 'check_circle',
      },
      {
        title: 'status_paldesk.past_incidents',
        routerLink: '/past-incidents',
        icon: 'pd-utilization',
      },
    ],
  };

  constructor(private _meta: Meta) {}

  ngOnInit(): void {
    if (environment.hide_from_crawler === 'true') {
      this._meta.addTag({ name: 'robots', content: 'noindex' });
    }
  }
}
