export class EnvironmentBase {
  static palfinger_api_management_subs_key = '';
  static domainsForApiManagementSubsKey: string[];

  static hide_from_crawler = 'false';

  static identityServerAuthConf: {
    whitelistedDomains: string[];
    whitelistedRoutes: string[];
    blacklistedRoutes: string[];
  } = {
    whitelistedDomains: [],
    whitelistedRoutes: [],
    blacklistedRoutes: [],
  };

  static apollo_graphql_uri = '';
  static report_pdf_url = '';
  static google_analytics_id = '';
  static environment = '';
  static palfinger_api_management = '';
  static palfinger_app_gateway = '';

  static oidc_clientid = '';
  static oidc_scope = '';

  static LINKS: {
    SECURITY_TOKEN_SERVICE: string;
    POST_LOGOUT_REDIRECT_URI: string;
    PALDESK_BASEPATH: string;
    STATUS_PALDESK_PAGE: string;
  } = {
    SECURITY_TOKEN_SERVICE: '',
    POST_LOGOUT_REDIRECT_URI: '',
    PALDESK_BASEPATH: '',
    STATUS_PALDESK_PAGE: '',
  };
  static FEATUREFLAGS: {
    USE_CSI_SURVEY: string;
  } = {
    USE_CSI_SURVEY: '',
  };
}
